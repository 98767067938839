function App() {
    return (
        <div className="container">
            <div className="text-center mt-5">
                <p className="p-10 fw-bold text-primary h4">zugravu.dev</p>                
                <hr />
                <div className="vstack gap-1">
                    <div><small className="text-muted">ZUGRAVU DEV S.R.L.</small></div>
                    <div><small className="text-muted">Reg. Com. J22/4400/18.11.2022</small></div>
                    <div><small className="text-muted">CUI 47202898 | CIF RO47339861</small></div>
                </div>
                {/* <p>
                    <a href="https://egradi.ro" target="_blank">eGradi.ro</a>,&nbsp;
                    <a href="https://labalta.ro" target="_blank">laBalta.ro</a>
                </p> */}
            </div>
        </div>
    );
}

export default App;
